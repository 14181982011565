<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reclamaciones')+': '+$t('Proyecto')}} </h3>
		</div>
		<div class="p-col-12" v-if="!mostrar">
			<div class="card">
                <DataTable :value="claims" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-mt-3" scrollDirection="both"
					:paginator="true" :rows="25">
                    <template #header>
                        <div class="table-header">
                            <div>
                                {{$t('Fecha')+'s'}}:
								<InputMask v-model="fecha1" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999" @change="Limpiar()"/>
								<InputMask v-model="fecha2" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999" @change="Limpiar()"/>
                            </div>
                            <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Titular de la póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                              
                        </template>
                    </Column>
                    <Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.poliz}}                        
                        </template>
                    </Column>
                    <Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                       
                        </template>
                    </Column>
                    <Column field="benef_nombre" :header="$t('Contraparte')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.benef_nombre}}
                        </template>
                    </Column>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
							<strong :style="'font-size: 12px;color:'+slotProps.data.color" v-if="i18n.locale() == 'es'">
								{{slotProps.data.estado_nombre}}
							</strong>
							<strong :style="'font-size: 12px;color:'+slotProps.data.color" v-if="i18n.locale() == 'en'">
								{{slotProps.data.estado_name}}
							</strong>
                        </template>
                    </Column>   
                   <Column field="total" :header="$t('Total')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(parseFloat(slotProps.data.total-slotProps.data.pagado))}}                              
                        </template>
                    </Column>
                    <Column field="estimacion" :header="$t('Monto de Reserva')"  headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.estimacion)}}                              
                        </template>
                    </Column>
                    <Column field="total" :header="$t('Pagar')" headerStyle="width: 180px">
                        <template #body="slotProps">
							<div v-if="!slotProps.data.benef_pagado">
								{{moneda(slotProps.data.total)}}
							</div>
							<div v-else>
								{{moneda(0)}}
							</div>
                        </template>
                    </Column>
                    <template #footer footerStyle="text-align:right">
						<div class="p-grid p-align-center vertical-container">
							<div class="p-col-11">
								{{$t('Reserva')}}: {{moneda(reserva)}} <br> 
								{{$t('Pagar')}}: {{moneda(totalbenf1)}} <br> 
								{{$t('Pago')}}: {{moneda(totalbenf2)}} <br> 
							</div>
							<div class="p-col-1">
								<Button icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()" v-if="claims.length>0"/>
							</div>
						</div>
                    </template>
                </DataTable>
			</div>
        </div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Póliza')}}</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-4">
								{{$t('Número')}}: <strong>{{seleccion.poliz.codigo}}</strong>			
							</div>
							<div class="p-col-2">
								{{$t('Cliente')}}: <strong>{{seleccion.cliente}}</strong>			
							</div>
							<div class="p-col-3" v-if="i18n.locale() == 'es'">
								<strong>{{seleccion.proyecto.tipopoliza_nombre}}</strong> 			
							</div>
							<div class="p-col-3" v-else>
								<strong>{{seleccion.proyecto.tipopoliza_name}}</strong> 			
							</div>
							<!--
							<div class="p-col-2" v-if="i18n.locale() == 'es'">
								{{$t('Tipo')}}: <strong>{{seleccion.poliz.tipo_nombre}}</strong> 			
							</div>
							<div class="p-col-2" v-else>
								{{$t('Tipo')}}: <strong>{{seleccion.poliz.tipo_name}}</strong> 			
							</div>
							-->
							<div class="p-col-2" v-if="i18n.locale() == 'es'">
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="seleccion.poliz.estado==4"/>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="seleccion.poliz.estado==5"/>
								<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="seleccion.poliz.estado==6"/>
								<strong>{{seleccion.poliz.estado_nombre}}</strong> 			
							</div>
							<div class="p-col-2" v-else>
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="seleccion.poliz.estado==4"/>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="seleccion.poliz.estado==5"/>
								<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="seleccion.poliz.estado==6"/>
								<strong>{{seleccion.poliz.estado_name}}</strong> 			
							</div>
							<div class="p-col-1">
								{{$t('Días')}}: <strong>{{seleccion.dias}}</strong>			
							</div>

						</div>
                        <Button :label="$t('Cerrar')" icon="pi pi-times" class="p-button-danger p-mr-2 p-mb-2" @click="mostrar = false"/>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Información Básica')}}</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-2">
								{{$t('Número ID')}}: <strong>{{seleccion.code}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Localidad')}}: <strong>{{seleccion.proyecto.localidadn}}</strong>			
							</div>
							<div class="p-col-7">
								{{$t('Nombre Completo')}}: <strong>{{seleccion.nombre}}</strong>			
							</div>
							<div class="p-col-6">
								{{$t('Correo')}}: <strong>{{seleccion.correo}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Teléfono móvil')}}: <strong>{{seleccion.celular}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Teléfono privado')}}: <strong>{{seleccion.tlflocal}}</strong>			
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion>
				<AccordionTab :header="$t('Beneficiario')+' '+(index+1)" v-for="(item, index) in seleccion.beneficiarios" :key="index">
					<h4>{{$t('Forence')}}</h4>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-9">
									{{$t('Observaciones')}}: <strong>{{item.observacion}}</strong> 			
								</div>
								<div class="p-col-3">
									{{$t('Fecha')}}: <strong>{{seleccion.forence_fecha}}</strong> 			
								</div>
								<hr>
								<div class="p-col-8">
									<div class="p-grid">
										<div class="p-col-12">
											<DataTable :value="item.forence" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
												:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
												<Column :header="$t('Descripción')">
													<template #body="slotProps">
														{{slotProps.data.descripcion}}<br>
														<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('De segunda')" v-if="slotProps.data.secunda"></Tag>
													</template>
												</Column>
												<Column :header="$t('Costo')">
													<template #body="slotProps">
														{{slotProps.data.costo}}
													</template>
												</Column>
											</DataTable>
										<br>
										</div>
										<hr>
										<div class="p-col-8">
											<strong>Subtotal:</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{parseFloat(item.subtotal).toFixed(2)}}</strong>	
										</div>
										<hr>
										<div class="p-col-4">
											<strong>{{$t('Impuesto')}}:</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{parseFloat(item.impuesto).toFixed(2)}}%</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{(parseFloat(item.subtotal)*parseFloat(item.impuesto/100)).toFixed(2)}}</strong> 			
										</div>
									</div>
								</div>
								<div class="p-col-4">
									<DataTable :value="item.anexos" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
										:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
										<Column :header="$t('Documentos')">
											<template #body="slotProps">
												{{slotProps.data.descripcion}}<br>
												<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('De segunda')" v-if="slotProps.data.secunda"></Tag>
											</template>
										</Column>
										<Column :header="$t('Ver')">
											<template #body="slotProps">
												<img style="width: 25px;" :src="url.upload+slotProps.data.codigo" v-if="slotProps.data.format=='jpg'"  @click="IMG(url.upload+slotProps.data.codigo)"/>
												<img style="width: 25px;" src="images/pdf.jpg" v-if="slotProps.data.format=='pdf'" @click="VerPDF(slotProps.data.codigo)"/>
											</template>
										</Column>
									</DataTable>
									<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
										<img style="width: 350px;" :src="imagen"/>
									</OverlayPanel>	
								<br>													
								</div>
								<hr>
								<div class="p-col-12"><br><br>
									<div class="p-grid">
										<div class="p-col-5">
											<h3><strong>{{$t('Total')}}:</strong></h3>
										</div>
										<hr>
										<div class="p-col-2">
											<h3><strong>{{parseFloat(item.total).toFixed(2)}}</strong></h3>	
										</div>
										<div class="p-field p-col-2">
											<strong>{{$t('Documento de Aceptación')}}</strong>
										</div>
										<div class="p-field p-col-2">
											<img style="width: 75px;" src="images/pdf.jpg" v-if="item.aceptacion" @click="VerPDF(item.aceptacion)"/>
										</div>
									</div>								
								</div>
								<br>
							</div>
						</div>
					</div>
				</AccordionTab>
			</Accordion>
		</div>

	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";
	import XLS from "../service/XLS";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: null,
				opciones: [{nombre: 'Todos', name: 'All', code: -1, color: ''}],
				resultado: [],
				insert: false,
				edit: false,
				del: false,
				poliza: null,
				filters: {},
				selectedCustomers: null,
				submitted: false,
				size: '60vw',
				polizas: [],
				seleccion: {},
				mostrar: false,
				forences: [],
				estados: [],
				validationErrors: {},
				estado: null,
				estado2: null,
				claims: [],
				bancos: [],
				pdf: null,
				lpdf: null,
				fecha1: null,
				fecha2: null,
				total: 0,
				reserva: 0,
				totalbenf1: 0,
				totalbenf2: 0
			}
		},
		created() {
			this.url = url;
			//this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			const tokgen = new Token(256);
			this.pdf = tokgen.generate();
			this.lpdf = tokgen.generate();
			const Consulta = new API('Reclamos');
			this.fecha1 = Consulta.fstring(this.$store.state.fecha);
			this.fecha2 = Consulta.fstring(this.$store.state.fecha);
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				this.claims = [];
				this.polizas = [];
				this.mostrar = false;
				const Consulta = new API('Reclamos');
				Consulta.Ini('Claim4').then(result => {
					//this.$store.state.error = result.consult.claimsmain[0];
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.minimo = result.consult.fecha;
					this.fecha1 = Consulta.fstring(result.consult.fecha);
					if(result.consult.polizas){
						this.polizas = result.consult.polizas;
					}
					if(result.consult.forences){
						this.forences = result.consult.forences;
					}
					if (result.consult.claimsforence){
						this.estados = result.consult.claimsforence;
					} 
					if (result.consult.opciones){
						result.consult.opciones.forEach(element => {
							this.opciones.push(element);
						});
					}
					this.$store.commit('Loading');
				}); 
			},
			Limpiar(){
				this.claims = [];	
				this.opcion = null;	
			},
			Buscar(){
				this.$store.commit('Loading');
				this.total = 0;
				this.reserva = 0;
				this.claims = [];
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim4',{
					caso: 	'Buscar',
					fecha1: Consulta.fdate(this.fecha1),
					fecha2: Consulta.fdate(this.fecha2),
					opcion: this.opcion.code,
					}).then(response => {
						//this.$store.state.error = response.result;	
						if (response.result.claims){
							this.claims = response.result.claims;
						}
						this.total = response.result.total;
						this.reserva = response.result.reserva;
						this.totalbenf1 = response.result.totalbenf1;
						this.totalbenf2 = response.result.totalbenf2;
						this.$store.commit('Loading');
					});
			},
			VerXLS(){
				const xls = new XLS();
				xls.Reporte3(this.claims,this.$store.state.currency,this.reserva,this.totalbenf1,this.totalbenf2);
			},
			Filtrar() {				
				if (this.opcion.code==0){
					this.claims = this.resultado;
				} else {
					this.claims = this.resultado.filter(val => val.estado == this.opcion.code);			
				}
			},
			Editar(data) {
				this.$store.commit('Loading');
				this.seleccion = {};
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim4',{
					caso: 	'MostMain',
					id: data.id,
					}).then(response => {
						//this.$store.state.error = response;	
						if (response.result.claimsmain){
							this.seleccion = response.result.claimsmain;
							this.pagado = 0;				
							this.estados.forEach(element => {
								if(this.seleccion.estado==element.code){
									this.estado = element;
								}
							});
							this.bancos = response.result.bancos;
							for (var i = 0; i < this.seleccion.beneficiarios.length; i++) {
								this.bancos.forEach(element => {
									if(this.seleccion.beneficiarios[i].banco==element.code){
										this.seleccion.beneficiarios[i].banco = element;
									}
								});
								if (this.seleccion.beneficiarios[i].pagado){
									this.pagado = parseFloat(this.pagado)+parseFloat(this.seleccion.beneficiarios[i].total);
								}
							}
							this.mostrar = true;
						}
						this.$store.commit('Loading');
					});
			},
			IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},
			VerPDF(document){
				window.open(url.upload+document+'.pdf', '_blank');
			},
			myUploader1(pdf) {
				this.$store.commit('Loading');
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim4',{
					caso: 	'RevPDF',
					foto: pdf,
				}).then(response => {
					//this.$store.state.error = response;	
					if(response.result){					
						this.informacion.lesionespdf = pdf;
						const tokgen = new Token(256);
						this.pdf = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			myUploader2(pdf) {
				this.$store.commit('Loading');
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim4',{
					caso: 	'RevPDF',
					foto: pdf,
				}).then(response => {
					//this.$store.state.error = response;	
					if(response.result){					
						this.informacion.aceptacion = pdf;
						const tokgen = new Token(256);
						this.pdf = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			moneda(number){
				return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
